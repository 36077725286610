import moment from "moment";
import { ProductMix } from "~/action-panel/components/common/product-blend-modal/model";
import { FieldAPI, RecAPI, RecModels, ZoneAPI, SearchAPI } from "@ai360/core";
import { ClassBreak } from "@ai360/core";
import Polygon from "@arcgis/core/geometry/Polygon";
import { v4 as uuid } from "uuid";

export const PROCESSING_SURFACES = "Processing Surfaces";
export const REC_TYPE_NAME_EQUATION_APPLICATION = "Application (Equation)";
export const REC_TYPE_NAME_MANUAL_APPLICATION = "Application (Manual)";
export const REC_TYPE_NAME_EQUATION_PLANTING = "Planting (Equation)";
export const REC_TYPE_NAME_MANUAL_PLANTING = "Planting (Manual)";
export const TERMINAL_STATUS_CODES = [0, 6, 8, 31, 35, 36]; // Empty, Complete, Processing Surfaces, Rec Failed, Exported, and Export Failed, respectively

export const NEWABLE_REC_TYPES = new Map([
    [REC_TYPE_NAME_EQUATION_APPLICATION, (role) => role.equationApplication],
    [REC_TYPE_NAME_MANUAL_APPLICATION, (role) => role.manualApplication],
    [REC_TYPE_NAME_EQUATION_PLANTING, (role) => role.equationPlanting],
    [REC_TYPE_NAME_MANUAL_PLANTING, (role) => role.manualPlanting],
]);

export interface IRecModelMethods {
    isAllRequiredFieldsSet: boolean;
    isAllRequiredFieldsSetForRun: boolean;
    updateRecModel(newProps: any): IRecModelMethods;
}

export interface IUseRec extends RecAPI.IRec {
    importedStatus: number;
}

export class RecNutrient implements RecAPI.IRecNutrient {
    public description: string | null;
    public recNutrientGuid: string | null;
    public recGuid: string | null;
    public nutrientGuid: string | null;
    public nutrientName: string | null;
    public equationId: string | null;
    public equationGuid: string | null;
    public equationName: string | null;
    public equationSuccess: boolean;
    public importFileGuid: string | null;
    public importFileColumnName: string | null;
    public isSelected: boolean;
    public missingEquationAttributes: any[] | null; // TODO: check on this
    public productBlendGuid: string | null;
    public productBlendName: string | null;
    public unitGuid: string | null;
    public unitName: string | null;
    public cost: string | null;
    public costUnitGuid: string | null;
    public creditedGridCells: any[] | null; // TODO: check on this
    public exportYn: boolean;
    public recNutrientProductMix: RecNutrientProductMix;
    public recNutrientParameters: RecNutrientParameters;
    public averageRecNutrientResult: RecNutrientResult;
    public averageCreditRecNutrientResult: RecNutrientResult;
    public averageAdjustedRecNutrientResult: RecNutrientResult;
    public credits: any[] | null; // TODO: check on this
    public isRecAdjustmentProcessing: boolean | null;
    constructor() {
        this.recNutrientGuid = "";
        this.recGuid = "";
        this.nutrientGuid = "";
        this.nutrientName = "";
        this.equationGuid = "";
        this.equationName = "";
        this.equationSuccess = false;
        this.importFileGuid = null;
        this.importFileColumnName = null;
        this.isSelected = true;
        this.missingEquationAttributes = [];
        this.productBlendGuid = "";
        this.productBlendName = "";
        this.unitGuid = "";
        this.unitName = "";
        this.cost = "";
        this.costUnitGuid = "";
        this.creditedGridCells = [];
        this.exportYn = true;
        this.recNutrientProductMix = new RecNutrientProductMix();
        this.recNutrientParameters = new RecNutrientParameters();
        this.averageRecNutrientResult = new RecNutrientResult();
        this.averageCreditRecNutrientResult = new RecNutrientResult();
        this.averageAdjustedRecNutrientResult = new RecNutrientResult();
        this.credits = [];
        this.isRecAdjustmentProcessing = false;
    }
}

export class RecNutrientParameters implements RecAPI.IRecNutrientParameters {
    public recNutrientParametersGuid: string | null;
    public recNutrientGuid: string | null;
    public totalProduct: string | null;
    public averageRate: string | null;
    public minimumRate: string | null;
    public maximumRate: string | null;
    public switchRate: string | null;
    public zerosBelowMinimum: boolean;
    public zerosBelowSwitchRate: boolean;
    public minimumIncludeZeros: boolean;
    public minimumExcludeZeros: boolean;
    public pricePerAcre: string | null;
    public percentAdjustment: string | null;
    public carrierVolume: string | null;
    public minimumLock: boolean | null;
    public maximumLock: boolean | null;
}

export class RecNutrientResult implements RecAPI.IRecNutrientResult {
    public baseNutrientRate?: string | null;
    public baseProductMax?: string | null;
    public baseProductMin?: string | null;
    public baseProductRate?: string | null;
    public recNutrientGuid: string | null;
    public productRate: string | null;
    public nutrientRate: string | null;
    public totalProduct: string | null;
    public area: string | null;
    public minRate: string | null;
    public maxRate: string | null;
    public pricePerAcre: string | null;
    public percentAdjustment: string | null;
    public appliedNutrientRate: string | null;
    public appliedProductRate: string | null;
    public appliedArea: string | null;
}

export class RecNutrientProductMix implements RecAPI.IRecNutrientProductMix {
    public recNutrientProductMixGuid: string | null;
    public name: string | null;
    public guaranteedAnalysis: string | null;
    public hasSavedBlend: boolean;
    public density: string | null;
    public densityUnitGuid: string | null;
    public physicalStateGuid: string | null;
    public targetRate: string | null;
    public targetRateUnit: string | null;
    public targetRateUnitGuid: string | null;
    public productMixTypeGuid: string | null;
    public productMixType: string | null;
    public limeEfficiency: string | null;
    public products: RecNutrientProduct[] | null;
    public nutrients: RecNutrientNutrient[] | null;
    public productBlendGuid: string | null;
    public costPerAcre: string | null;
    public totalCost: string | null;
    public totalProduct: string | null;
    constructor() {
        this.recNutrientProductMixGuid = "";
        this.name = "";
        this.guaranteedAnalysis = "";
        this.density = "";
        this.densityUnitGuid = "";
        this.physicalStateGuid = "";
        this.targetRate = "";
        this.targetRateUnit = "";
        this.targetRateUnitGuid = "";
        this.productMixTypeGuid = "";
        this.productMixType = "";
        this.limeEfficiency = "";
        this.products = [];
        this.nutrients = [];
    }
}

export class RecNutrientProduct implements RecAPI.IRecNutrientProduct {
    public recNutrientProductMixGuid: string | null;
    public productGuid: string | null;
    public productName: string | null;
    public customProductName: string | null;
    public customProductGuid: string | null;
    public percentOfMix: string | null;
    public rate: string | null;
    public rateUnitGuid: string | null;
    public cost: string | null;
    public costUnitGuid: string | null;
    public costPerAcre: string | null;
    public totalCost: string | null;
    public totalProduct: string | null;
    public limeEfficiency: string | null;
}

export class RecNutrientNutrient implements RecAPI.IRecNutrientNutrient {
    public recNutrientProductMixGuid: string | null;
    public nutrientGuid: string | null;
    public nutrientPercent: string | null;
}

export class RecPlanting implements RecAPI.IRecPlanting {
    public recGuid: string | null;
    public cropGuid: string | null;
    public cropName: string | null;
    public cropClassNameGuid: string | null;
    public cropPurposeGuid: string | null;
    public cropPurposeName: string | null;
    public plantingDepth: string | null;
    public plantingDepthIaGuid: string | null;
    public rowSpacing: string | null;
    public rowSpacingIaGuid: string | null;
    public rowOrientationGuid: string | null;
    public isImportedYn: boolean;
    public transplantYn: boolean;
    public plantSpacing: string | null;
    public plantSpacingIaGuid: string | null;
    public transplantTypeGuid: string | null;
    public plantingPatternGuid: string | null;
    public cropAgeWhenPlanted: string | null;
    public cropAgeIaGuid: string | null;
    public recPlantingVarietyHybridList: any[] | null;

    constructor() {
        this.cropAgeWhenPlanted = null;
        this.cropGuid = "";
        this.cropName = "";
        this.cropPurposeGuid = "";
        this.cropPurposeName = "";
        this.recPlantingVarietyHybridList = [];
        this.isImportedYn = false;
        this.plantSpacing = null;
        this.plantingDepth = null;
        this.plantingDepthIaGuid = null;
        this.rowOrientationGuid = null;
        this.rowSpacing = null;
        this.rowSpacingIaGuid = null;
        this.transplantYn = false;
    }
}

export class RecPlantingVarietyHybrid implements RecAPI.IRecPlantingVarietyHybrid {
    public recPlantingVarietyHybridGuid: string | null;
    public recGuid: string | null;
    public sectionId: string | null;
    public brandOrganizationGuid: string | null;
    public brandOrganizationName: string | null;
    public varietyHybridGuid: string | null;
    public varietyHybridName: string | null;
    public seedingRate: string | null;
    public seedingRateIaGuid: string | null;
    public plantsPerArea: string | null;
    public plantsPerAreaIAGuid: string | null;
    public pricePerUnit: string | null;
    public pricePerUnitGuid: string | null;
    public seedCount: string | null;
    public rootstockName: string | null;
    public lotNumber: string | null;
    public percentOfPlanter: string | null;
    public totalProduct: string | null;
    public seedTechnologyProductGuid: string | null;
    public seedTechnologyPrice: string | null;
    public seedTechnologyFeeTotal: string | null;
    public missingEquationAttributes: any[] | null;
    public exportYn: boolean;
    public equationGuid: string | null;

    constructor() {
        this.recPlantingVarietyHybridGuid = null;
        this.recGuid = null;
        this.sectionId = null;
        this.brandOrganizationGuid = null;
        this.brandOrganizationName = null;
        this.varietyHybridGuid = null;
        this.varietyHybridName = null;
        this.seedingRate = null;
        this.seedingRateIaGuid = null;
        this.plantsPerArea = null;
        this.plantsPerAreaIAGuid = null;
        this.pricePerUnit = null;
        this.pricePerUnitGuid = null;
        this.seedCount = null;
        this.rootstockName = null;
        this.lotNumber = null;
        this.percentOfPlanter = "100";
        this.totalProduct = null;
        this.seedTechnologyProductGuid = null;
        this.seedTechnologyPrice = null;
        this.seedTechnologyFeeTotal = null;
        this.missingEquationAttributes = [];
        this.exportYn = true;
    }
}

export class RecEquationFilter implements RecAPI.IRecEquationFilter {
    public recEquationFilterGuid: string | null;
    public recGuid: string | null;
    public cropGuid: string | null;
    public cropClassNameGuid: string | null;
    public cropPurposeGuid: string | null;
    public previousCropGuid: string | null;
    public nextCropGuid: string | null;
    public productGuid: string | null;
    public tillageMethodGuid: string | null;
    public applicationTimingGuid: string | null;
    public applicationMethodGuid: string | null;
    public soilTextureGuid: string | null;
    public incorporationDepthGuid: string | null;
    public buildFactorGuid: string | null;
    public targetpHGuid: string | null;
    public recOptionGuid: string | null;
    public activeEquationGroupGuid: string | null;

    constructor() {
        this.recEquationFilterGuid = null;
        this.recGuid = null;
        this.cropGuid = null;
        this.cropClassNameGuid = null;
        this.cropPurposeGuid = null;
        this.previousCropGuid = null;
        this.nextCropGuid = null;
        this.productGuid = null;
        this.tillageMethodGuid = null;
        this.applicationTimingGuid = null;
        this.applicationMethodGuid = null;
        this.soilTextureGuid = null;
        this.incorporationDepthGuid = null;
        this.buildFactorGuid = null;
        this.targetpHGuid = null;
        this.recOptionGuid = null;
        this.activeEquationGroupGuid = null;
    }
}

export class RecAreaPolygon implements ZoneAPI.IZonePolygon {
    public polygonGuid: string | null = null;

    constructor(public shape: Polygon | null) {}
}

export class Rec implements RecAPI.IRec, IRecModelMethods {
    get isAllRequiredFieldsSet(): boolean {
        return (
            Boolean(this.isIncluded && this.equationGroupGuid) ||
            (this.productMixList != null && this.productMixList.length > 0) ||
            (this.recPlanting != null &&
                !this.equationGroupGuid &&
                Boolean(this.recPlanting.cropGuid))
        );
    }

    get isAllRequiredFieldsSetForRun(): boolean {
        const equationParametersAreValid = () => {
            for (const ep of this.equationParameters) {
                if (ep.required && !ep.value) {
                    return false;
                }
            }
            return true;
        };
        return Boolean(equationParametersAreValid());
    }

    public static resetRecModel(rec: Rec): Readonly<Rec> {
        const recModel: Rec = new Rec();
        return Rec.updateRec(rec, recModel);
    }

    public static updateRec(rec: Rec, newProps: Partial<IUseRec>): Readonly<Rec> {
        console.assert(rec instanceof Rec);
        const rv: Rec = new Rec();

        if (rec.recGuid && newProps.recGuid === null) {
            // the guid has been cleared out b/c it's a new zone, we need to wipe the child table PK's
            newProps.recPlanting = {
                ...rec.recPlanting,
                ...newProps.recPlanting,
                recGuid: null,
                recPlantingVarietyHybridList: (newProps.recPlanting
                    ? newProps.recPlanting.recPlantingVarietyHybridList
                    : rec.recPlanting.recPlantingVarietyHybridList
                ).map((vh) => ({
                    ...vh,
                    recGuid: null,
                    recPlantingVarietyHybridGuid: null,
                })),
            };
            newProps.productMixList = (
                newProps.productMixList ? newProps.productMixList : rec.productMixList
            ).map((pm) => ({
                ...pm,
                productMixGuid: null,
                eventRecGuid: null,
                products: pm.products.map((p) => ({
                    ...p,
                    productMixProductGuid: null,
                    productMixGuid: null,
                })),
                nutrients: pm.nutrients.map((n) => ({
                    ...n,
                    productMixNutrientGuid: null,
                    productMixGuid: null,
                })),
            }));
            // Note: there's no access to the zone tools after run in equation application/planting, so it's
            //  subsequently impossible to get the data with duplicated primary keys after exploding a classified multi-poly
            // Thus, the lack of treatment for recNutrientList and the other equation based child objects
        }

        Object.assign(rv, rec, newProps);
        return Object.freeze(rv);
    }
    public recGuid: string | null = "";
    public recAreaGuid: string | null = "";
    public recStatus: string | null = "";
    public equationGroupGuid: string | null = "";
    public cropGuid: string | null = "";
    public cropPurposeGuid: string | null = "";
    public varietyHybridGuid: string | null = "";
    public cropPlantingDate: string | null = "";
    public yieldGoal: string | null = "";
    public yieldGoalUnitGuid: string | null = "";
    public applicationMethodGuid = "";
    public applicationTimingGuid = "";
    public releasedYn = false;
    public recNutrientList: RecNutrient[] | null = [];
    public productMixList: ProductMix[] | null = [];
    public recPlanting: RecPlanting | null = new RecPlanting();
    public equationParameters: RecModels.RecEquationParameter[] | null = [];
    public equationFilters: RecEquationFilter | null = new RecEquationFilter();
    public isEquationRunEnabled: boolean | null;
    public isEquationRun: boolean | null;
    public isAnyEquationRun: boolean | null;
    public isAnyRecAdjustmentProcessing: boolean | null;
    public isIncluded: boolean | null = true;
    public isRecDirty: boolean | null;
    public isNew: boolean | null;
    public isFilterChanged: boolean | null;

    constructor() {
        this.isRecDirty = false;
        this.isFilterChanged = false;
    }

    public updateRecModel(newProps: Record<string, unknown>): Rec {
        return Object.assign(new Rec(), this, newProps);
    }
}

export class RecArea implements RecAPI.IRecArea {
    public static updateRecArea(
        recArea: RecArea,
        newProps: Partial<RecAPI.IRecArea>
    ): Readonly<RecArea> {
        console.assert(recArea instanceof RecArea);
        const rv = new RecArea(null, null);
        Object.assign(rv, recArea, newProps);
        return Object.freeze(rv);
    }
    public recAreaGuid = "";
    public recAreaId = 1;
    public recGeneralGuid = "";
    public applyRecToArea = false;
    public recAreaClassBreak: ClassBreak | null = null;
    public calculatedArea = 0;
    public fieldBoundaryGuid: string | null = null;

    constructor(
        public zonePolygons: RecAreaPolygon[] | null,
        public recs: Rec[],
        calculatedArea = 0,
        applyRecToArea = false
    ) {
        this.applyRecToArea = applyRecToArea;
        this.calculatedArea = calculatedArea;
    }
}

export class RecTypeInfo implements RecAPI.IRecType {
    public static fromGetRecTypeResponseObj(
        jsonObj: RecAPI.IGetRecTypeResponse
    ): Readonly<RecTypeInfo> {
        const { recTypeGuid, name } = jsonObj;
        const rv = new RecTypeInfo(recTypeGuid, name);
        return Object.freeze(rv);
    }

    public static fromJsonObj(jsonObj: RecAPI.IRecType): Readonly<RecTypeInfo> {
        const rv = new RecTypeInfo(jsonObj.recTypeGuid, jsonObj.name);
        return Object.freeze(rv);
    }

    constructor(public recTypeGuid: string, public name: string) {}
}

export class RecEventSelection implements RecAPI.IRecEventSelection {
    public recEventSelectionGuid: string;

    constructor(
        recEventSelectionGuid?: string,
        public recGeneralGuid?: string,
        public agEventGeneralGuid?: string,
        public croppingSeasonName?: string,
        public eventTypeName?: string,
        public isDefaultSelection?: boolean
    ) {
        this.recEventSelectionGuid = recEventSelectionGuid || uuid();
    }

    public static fromJsonObj(jsonObj: RecAPI.IRecEventSelection): RecEventSelection {
        const rv = new RecEventSelection();
        Object.assign(rv, jsonObj);
        return Object.freeze(rv);
    }
}

export class RecDetails implements RecAPI.IRecDetails {
    public static updateRecDetails(
        recDetails: RecDetails,
        newProps: Partial<RecDetails>
    ): Readonly<RecDetails> {
        console.assert(recDetails instanceof RecDetails);
        const { fieldGuid, fieldBoundaryGuid, recTypeGuid, recType, croppingSeasonGuid } =
            recDetails;
        const rv = new RecDetails(
            fieldGuid,
            fieldBoundaryGuid,
            [{ recTypeGuid, name: recType }],
            croppingSeasonGuid
        );
        Object.assign(rv, recDetails, newProps);
        return Object.freeze(rv);
    }

    public static fromJsonObj(jsonObj: RecAPI.IRecDetails): RecDetails {
        const {
            recTypeGuid,
            recType,
            recAreaList,
            fieldGuid,
            fieldBoundaryGuid,
            croppingSeasonGuid,
            eventSelectionList,
            ...simpleFields
        } = jsonObj;
        const recTypeList = [{ recTypeGuid, name: recType }];
        const rv = new RecDetails(fieldGuid, fieldBoundaryGuid, recTypeList, croppingSeasonGuid);
        Object.assign(rv, simpleFields);

        rv.recAreaList = recAreaList.map((recAreaJsonObj) => {
            Object.setPrototypeOf(recAreaJsonObj.zonePolygons, [RecAreaPolygon.prototype]);
            if (recAreaJsonObj.recAreaClassBreak != null) {
                Object.setPrototypeOf(recAreaJsonObj.recAreaClassBreak, ClassBreak.prototype);
            }

            recAreaJsonObj.recs = recAreaJsonObj.recs.map((recJsonObj) => {
                let rec = new Rec();
                rec = rec.updateRecModel({
                    ...recJsonObj,
                    equationFilters: recJsonObj.equationFilters || rec.equationFilters,
                });
                return rec;
            });
            return RecArea.updateRecArea(new RecArea(null, null), recAreaJsonObj);
        });
        rv.momentCreatedDate = jsonObj.recDate ? moment(jsonObj.recDate, "MM-DD-YYYY") : moment();
        rv.eventSelectionList = eventSelectionList.map((recEventSelectionJsonObj) => {
            return RecEventSelection.fromJsonObj(recEventSelectionJsonObj);
        });
        return Object.freeze(rv);
    }
    public recGeneralGuid = "";
    public name = "";
    public displayName: string | null = "";
    public recTypeGuid: string | null;
    public recType: string | null;
    public recStatuses: string | null = "";
    public croppingSeasonCycleGuid: string | null = "";
    public cropGuid: string | null = "";
    public cropClassNameGuid: string | null = "";
    public cropPurposeGuid: string | null = "";
    public cropPlantingDate: string | null = "";
    public varietyHybridGuid: string | null = "";
    public brandOrganizationGuid: string | null = "";
    public notes: string | null = "";
    public planName: string | null = "";
    public recDate: string | null = "";
    public personList: any[];
    public guaranteedAnalysis: string | null = "";
    public modifiedDate: string | null = "";
    public modifiedBy: string | null = "";
    public recAreaList: RecArea[];
    public importedStatus: number | null;
    public isClassified: boolean;
    public surfaceRendererVectorGuid: string | null;
    public missingAttributes: string | null;
    public failureAttributes: string | null;
    public zoneFileGuid: string | null;
    public eventSelectionList: RecEventSelection[];
    public customerGuid: string | null = null;

    public momentCreatedDate = moment();

    constructor(
        public fieldGuid: string,
        public fieldBoundaryGuid: string,
        recTypeList: RecTypeInfo[],
        public croppingSeasonGuid: string | null
    ) {
        this.recTypeGuid = recTypeList.length > 0 ? recTypeList[0].recTypeGuid : "";
        this.recType = recTypeList.length > 0 ? recTypeList[0].name : "";
        this.recAreaList = [new RecArea(null, [new Rec()])];
        this.personList = [];
        this.guaranteedAnalysis = "0-0-0";
        this.eventSelectionList = [];
    }

    public isMultipartZoneAutoSplitAllowed(): boolean {
        const isEquationRec =
            this.recType === REC_TYPE_NAME_EQUATION_APPLICATION ||
            this.recType === REC_TYPE_NAME_EQUATION_PLANTING;
        let isSplitAllowed = !isEquationRec; // Initializing such that any non-equation rec situations will default true
        if (isEquationRec) {
            const recAreaList = this.recAreaList;
            // Already established that it's a single zone/multi-part, so we can just take the first rec area
            const recList = !recAreaList || recAreaList.length === 0 ? [] : recAreaList[0].recs;
            // Don't allow an equation rec to have a multi-part field split up after it's been ran successfully
            isSplitAllowed =
                !recList || recList.length === 0
                    ? false
                    : !recList.some(
                          (rec) =>
                              rec.isEquationRun ||
                              rec.recNutrientList.some((recNutrient) => recNutrient.equationSuccess)
                      );
        }
        return isSplitAllowed;
    }
}

export class RecSummary {
    get croppingSeasonNum(): number {
        return Number(this.croppingSeasonName);
    }

    get recDateParsed(): Date {
        return moment(this.recDate, "MM-DD-YYYY").toDate();
    }

    public static fromJsonObj(
        jsonObj: FieldAPI.IRecSummary,
        field: SearchAPI.IFieldResult
    ): Readonly<RecSummary> {
        const rv = new RecSummary();
        Object.assign(rv, jsonObj);
        rv.fieldName = field.name;
        rv.farmName = field.farmName;
        rv.fieldAcres = field.acres;
        rv.fieldBoundaryGuid = field.boundaryId;
        rv.customerGuid = field.customerId;
        rv.customerName = field.customerName;
        rv.customerEnrolled = field.customerEnrolled;
        return Object.freeze(rv);
    }

    public static fromRecDetails(
        recDetails: RecDetails,
        fieldBoundaryGuid: string = null,
        fieldName: string = null,
        farmName: string = null,
        fieldAcres: number = null,
        customerName: string = null
    ): Readonly<RecSummary> {
        const rv = new RecSummary();
        rv.recTypeGuid = recDetails.recTypeGuid;
        rv.recDate = recDetails.momentCreatedDate.format("M-D-YY");
        rv.recType = recDetails.recType;
        rv.recDisplayName = [recDetails.recType, rv.recDate, recDetails.name]
            .filter((x) => x)
            .join(" - ");
        rv.fieldGuid = recDetails.fieldGuid;
        rv.importedStatus = 0;
        rv.modifiedDate = recDetails.modifiedDate;
        rv.modifiedBy = recDetails.modifiedBy;
        rv.fieldBoundaryGuid = fieldBoundaryGuid;
        rv.fieldName = fieldName;
        rv.farmName = farmName;
        rv.fieldAcres = fieldAcres;
        rv.customerName = customerName;
        return Object.freeze(rv);
    }

    public activeYn: boolean;
    public recDate: string;
    public cropDisplayName: string;
    public croppingSeasonGuid: string;
    public croppingSeasonName: string;
    public cropGuids: string[];
    public fieldGuid: string;
    public fieldName: string;
    public fieldAcres: number;
    public farmName: string;
    public importedStatus: number;
    public isBatchEdit: boolean;
    public modifiedDate: string;
    public modifiedBy: string;
    public recDisplayName: string;
    public recGeneralGuid: string;
    public recName: string;
    public recGuids: string[];
    public recStatuses: string;
    public recType: string;
    public recTypeGuid: string;
    public fieldBoundaryGuid: string;
    public customerGuid: string;
    public customerName: string;
    public customerEnrolled: boolean;
    public isImportedYn: boolean | undefined;

    public compare(rightHandSide: RecSummary): number {
        console.assert(rightHandSide instanceof RecSummary);
        if (this.croppingSeasonNum !== rightHandSide.croppingSeasonNum) {
            return this.croppingSeasonNum - rightHandSide.croppingSeasonNum;
        }
        const thisRecSummaryDate = this.recDateParsed;
        const rhsRecSummaryDate = rightHandSide.recDateParsed;
        return thisRecSummaryDate < rhsRecSummaryDate
            ? -1
            : thisRecSummaryDate > rhsRecSummaryDate
            ? 1
            : 0;
    }

    public updateImportedStatus(importedStatus: number): RecSummary {
        const rv = new RecSummary();
        Object.assign(rv, this, { importedStatus });
        return Object.freeze(rv);
    }

    public updateRecStatus(recStatus: string): RecSummary {
        const rv = new RecSummary();
        Object.assign(rv, this, { recStatuses: recStatus });
        return Object.freeze(rv);
    }
}
