import { FieldAPI, ReportAPI, SetupReportBooksAPI, Request } from "@ai360/core";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { InjectedIntlProps, injectIntl } from "react-intl";

import { OrgLevelList } from "~/admin/agBytes/components/org-level-list";
import { adminData } from "~/admin/data";
import { messages } from "~/admin/setup/i18n-messages";
import {
    IDataTableReport,
    IMergeReportBookData,
    IMergeReportOptions,
    IMergeReportOptionsData,
    IReportBookInfo,
    IReportOptions,
    IReportType,
    ISoilTypeAttribute,
} from "~/admin/setup/report-books/data/interfaces";
import { getAgBytesErrorClassNames } from "~/admin/utils";
import {
    Button,
    Checkbox,
    DataTable,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    LoaderTypes,
    Section,
    SelectInput,
    SubSection,
    TextArea,
    TextInput,
} from "~/core";
import { DownArrowIcon, TrashcanIcon, UpArrowIcon } from "~/core/icons";
import { MSGTYPE } from "~/notifications";
import { ReportOptionsContainer } from "~/reports/components";
import "../../../../reports/reports.css";
import * as model from "../data/model";
import * as reportModel from "~/reports/data/model";
import { addEditService } from "../data/service";

import "./add-edit-panel.css";
import { setReportPreferences } from "~/admin/setup/utils/report-book-utils";
import { IReportTypePage } from "../../../../reports/data/interfaces";

const ADD = "ADD";
const COPY_REPORT_BOOK_MODAL = "copyReportBookModal";
const DELETE_CONFIRMATION_MODAL = "deleteConfirmationModal";
const REPORT_BOOK_MODAL = "reportBookModal";
const REPORT_OPTIONS_MODAL = "reportOptionsModal";
const VALIDATION_MODAL = "validationModal";

interface IAddEditPanelProps {
    apiErrors: any[];
    companyGuid: string;
    fetchData: boolean;
    filteredLocationList: any[];
    getFilteredLocationList: () => void;
    getReportBookTypeNames: () => string[];
    getReportBookOrgLevelGuids: (guid: string) => SetupReportBooksAPI.IOrgLevelGuidsResponse;
    getReportBookReports: (guid: string) => SetupReportBooksAPI.IReportResponse;
    getReportOptions: (guid?: string) => SetupReportBooksAPI.IReportOptionsResponse;
    getReportTypes: () => SetupReportBooksAPI.IReportTypeResponse;
    getSoilTypeAttributes: () => SetupReportBooksAPI.ISoilTypeAttributeResponse;
    lastUsedOwnerGuid: string;
    liftCopyReportBook: () => void;
    liftErrorData: (data: {
        reportBookNameError: boolean;
        reportsLength: number;
        orgLevelGuidsLength: number;
    }) => void;
    liftRecordData: ({ ...args }: IMergeReportBookData) => void;
    loadingModal: boolean;
    loadingOrgLevelGuids: boolean;
    loadingReportBookReports: boolean;
    loadingReportBooks: boolean;
    mergeReportData: (reportType: string, reportData: Record<string, any>) => void;
    mergeReportOptions: (data: IMergeReportOptions) => SetupReportBooksAPI.IMergeResult;
    needs: (...args: any[]) => any;
    onPushToasterMessage: (message: string, type: string) => void;
    onUpdateCurrentCoverPage: (currentCoverPage: string) => void;
    onUpdateS3CleanupList: (filename: string, isOriginal: boolean) => void;
    reportBookNameError: boolean;
    reportBookNames: SetupReportBooksAPI.IReportBookNameRecord[];
    reportBookOrgLevelGuids: SetupReportBooksAPI.IOrgLevelGuidRecord[];
    reportBookReports: SetupReportBooksAPI.IReportRecord[];
    reportOptions: IReportOptions[];
    reportPreferences: any;
    reportTypePages: IReportTypePage[];
    reportTypes: IReportType[];
    resetReportData: () => void;
    setReportDataValue: (reportType: string, key: string, value: any) => void;
    selectedReportBookInfo: IReportBookInfo;
    selectedReportBookTypeGuid: string;
    selectedReportOptionGuid: string;
    setCompanyUserPreference: (guid: string) => void;
    showCopyReportBookModal: boolean;
    soilTypeAttributes: ISoilTypeAttribute[];
}

interface IAddEditPanelState {
    addOrEditMode: string;
    configurationName: string;
    configurationNameError: boolean;
    currentReportIndex: number;
    filteredReportOptions: IReportOptions[];
    loadingModal: boolean;
    loadingOrgLevelGuids: boolean;
    loadingReportBookReports: boolean;
    loadingReportBooks: boolean;
    loadingS3CoverPage: boolean;
    modals: IModalArray[];
    mode: string;
    newReportBookName: string;
    orgLevelList: any[];
    processing: boolean;
    reportBookInfo: Partial<IReportBookInfo>;
    reportBookNameError: boolean;
    reportBookNames: SetupReportBooksAPI.IReportBookNameRecord[];
    reportBookNewNameError: boolean;
    reportBookOrgLevelGuids: string[];
    reportBookReports: SetupReportBooksAPI.IReportRecord[];
    reportGuid: string;
    reportOptions: IReportOptions[];
    reportPreferences: any;
    reportTypePages: any[];
    reportTypes: IReportType[];
    reports: IDataTableReport[];
    selectedCompany: string;
    selectedItemIndex: number;
    selectedOrgLevels: FieldAPI.IOrgLevelInfo[];
    selectedReportBookInfo: IReportBookInfo;
    selectedReportBookTypeGuid: string;
    selectedReportOption: IReportOptions;
    selectedReportOptionGuid: string;
    selectedReportType: IReportType;
    selectedReportTypeGuid: string;
    showAddEditModal: boolean;
    soilTypeAttributes: ISoilTypeAttribute[];
    tempReportBookName: string;
    validationMessage: string;
}

interface IModalArray {
    id: string;
    isOpen: boolean;
}

export class AddEditPanel extends Component<
    IAddEditPanelProps & InjectedIntlProps,
    IAddEditPanelState
> {
    private tempConfigurationData: Record<string, any> = {};
    private dragDropDiv: React.ReactInstance = null;
    private dragDropNode: any = null;
    private fileInput: HTMLInputElement;

    constructor(props: IAddEditPanelProps) {
        super(props);
        this.state = {
            addOrEditMode: ADD,
            configurationName: null,
            configurationNameError: null,
            currentReportIndex: null,
            filteredReportOptions: [],
            loadingModal: false,
            loadingOrgLevelGuids: false,
            loadingReportBookReports: false,
            loadingReportBooks: false,
            loadingS3CoverPage: false,
            modals: [
                { id: COPY_REPORT_BOOK_MODAL, isOpen: false },
                { id: DELETE_CONFIRMATION_MODAL, isOpen: false },
                { id: REPORT_BOOK_MODAL, isOpen: false },
                { id: REPORT_OPTIONS_MODAL, isOpen: false },
                { id: VALIDATION_MODAL, isOpen: false },
            ],
            mode: null,
            newReportBookName: null,
            orgLevelList: [],
            processing: false,
            reportBookInfo: {},
            reportBookNameError: false,
            reportBookNames: [],
            reportBookNewNameError: false,
            reportBookOrgLevelGuids: [],
            reportBookReports: null,
            reportGuid: null,
            reportPreferences: {},
            reportOptions: [],
            reportTypePages: [],
            reportTypes: [],
            reports: [],
            selectedCompany: null,
            selectedItemIndex: null,
            selectedOrgLevels: [],
            selectedReportBookInfo: null,
            selectedReportBookTypeGuid: null,
            selectedReportOption: null,
            selectedReportOptionGuid: null,
            selectedReportType: null,
            selectedReportTypeGuid: null,
            showAddEditModal: false,
            soilTypeAttributes: [],
            tempReportBookName: null,
            validationMessage: null,
        };
    }

    UNSAFE_componentWillMount(): void {
        this.props.getReportBookTypeNames();
        this.props.getReportOptions(null);
        this.props.getReportTypes();
        this.props.getSoilTypeAttributes();
        this.props.getFilteredLocationList();
        this.props.needs([
            this.props.getReportTypePageList({
                url: ReportAPI.GET_REPORT_TYPE_PAGE_LIST_URL,
            }),
        ]);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAddEditPanelProps): void {
        const newState: Partial<IAddEditPanelState> = {};

        if (nextProps.fetchData) {
            this.generateReportBookData();
        }

        if (nextProps.filteredLocationList !== this.props.filteredLocationList) {
            newState.orgLevelList = nextProps.filteredLocationList;
            if (newState.orgLevelList.length === 1) {
                newState.selectedOrgLevels = [{ ...newState.orgLevelList[0] }];
            } else if (this.state.reportBookOrgLevelGuids.length > 0) {
                newState.selectedOrgLevels = this.generateSelectedOrgLevels(
                    this.state.reportBookOrgLevelGuids,
                    newState.orgLevelList
                ).filter(Boolean);
            }
        }

        if (
            nextProps.reportTypes != null &&
            JSON.stringify(nextProps.reportTypes) !== JSON.stringify(this.state.reportTypes)
        ) {
            newState.reportTypes = nextProps.reportTypes
                .map((rt) => ({
                    ...rt,
                    label: rt.displayName,
                    value: rt.reportTypeGuid,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
        }

        if (nextProps.lastUsedOwnerGuid !== this.props.lastUsedOwnerGuid) {
            newState.selectedCompany = nextProps.lastUsedOwnerGuid;
        }

        if (nextProps.loadingModal !== this.props.loadingModal) {
            newState.loadingModal = nextProps.loadingModal;
        }

        if (nextProps.loadingOrgLevelGuids !== this.props.loadingOrgLevelGuids) {
            newState.loadingOrgLevelGuids = nextProps.loadingOrgLevelGuids;
        }

        if (nextProps.loadingReportBookReports !== this.props.loadingReportBookReports) {
            newState.loadingReportBookReports = nextProps.loadingReportBookReports;
        }

        if (nextProps.loadingReportBooks !== this.props.loadingReportBooks) {
            newState.loadingReportBooks = nextProps.loadingReportBooks;
        }

        if (nextProps.reportBookNameError !== this.props.reportBookNameError) {
            newState.reportBookNameError = nextProps.reportBookNameError;
        }

        if (nextProps.reportBookNames !== this.props.reportBookNames) {
            newState.reportBookNames = nextProps.reportBookNames;
        }

        if (nextProps.reportBookOrgLevelGuids !== this.props.reportBookOrgLevelGuids) {
            newState.reportBookOrgLevelGuids = nextProps.reportBookOrgLevelGuids.map(
                (reportBookOrgLevelGuid) => reportBookOrgLevelGuid.orgLevelGuid
            );
            newState.selectedOrgLevels = this.generateSelectedOrgLevels(
                newState.reportBookOrgLevelGuids,
                this.state.orgLevelList
            ).filter(Boolean);
        }

        if (nextProps.reportBookReports !== this.props.reportBookReports) {
            const tempReports = [];
            newState.reports = nextProps.reportBookReports.map((rbr) => {
                const data = {
                    reportBookOptionsGuid: rbr.reportBookOptionsGuid,
                    sortOrder: rbr.sortOrder,
                };
                const newReport = this.generateReportTableData(
                    data,
                    rbr.reportName,
                    rbr.reportOptionsName
                );
                if (tempReports.length > 0) {
                    tempReports[tempReports.length - 1][model.PROPS_ADD_EDIT_TABLE_DOWN_BUTTON] =
                        this.generateReorderButton("desc", tempReports.length - 1);
                }
                tempReports.push(newReport);
                return newReport;
            });
        }

        if (nextProps.reportOptions !== this.props.reportOptions) {
            newState.reportOptions = nextProps.reportOptions.map((ro) => ({
                ...ro,
                label: ro.name,
                value: ro.reportBookOptionsGuid,
            }));
            newState.filteredReportOptions = newState.reportOptions;
        }

        if (nextProps.reportPreferences !== this.props.reportPreferences) {
            newState.reportPreferences = nextProps.reportPreferences;
        }

        if (nextProps.reportTypePages !== this.props.reportTypePages) {
            newState.reportTypePages = nextProps.reportTypePages;
        }

        if (
            JSON.stringify(nextProps.selectedReportBookInfo) !==
            JSON.stringify(this.state.selectedReportBookInfo)
        ) {
            const reportBookInfoIsNull = nextProps.selectedReportBookInfo == null;
            newState.reportBookInfo = {
                [model.PROPS_ACTIVE_YN]: reportBookInfoIsNull
                    ? true
                    : nextProps.selectedReportBookInfo.activeYN,
                [model.PROPS_NAME]: reportBookInfoIsNull
                    ? ""
                    : nextProps.selectedReportBookInfo.name,
                [model.PROPS_COVER_PAGE]: reportBookInfoIsNull
                    ? false
                    : nextProps.selectedReportBookInfo.coverPage,
                [model.PROPS_COVER_PAGE_URL]: nextProps.selectedReportBookInfo?.coverPage
                    ? nextProps.selectedReportBookInfo?.coverPageUrl
                    : null,
                [model.PROPS_MEMO]: reportBookInfoIsNull
                    ? ""
                    : nextProps.selectedReportBookInfo.memo,
            };
            newState.tempReportBookName = newState.reportBookInfo[model.PROPS_NAME];
            newState.selectedReportBookInfo = nextProps.selectedReportBookInfo;
        }

        if (nextProps.selectedReportBookTypeGuid !== this.state.selectedReportBookTypeGuid) {
            newState.selectedReportBookTypeGuid = nextProps.selectedReportBookTypeGuid;
            this.props.getReportBookOrgLevelGuids(newState.selectedReportBookTypeGuid);
            this.props.getReportBookReports(newState.selectedReportBookTypeGuid);
        }

        if (nextProps.selectedReportOptionGuid !== this.props.selectedReportOptionGuid) {
            newState.selectedReportOptionGuid = nextProps.selectedReportOptionGuid;
        }

        if (nextProps.soilTypeAttributes !== this.props.soilTypeAttributes) {
            newState.soilTypeAttributes = nextProps.soilTypeAttributes
                .map((sta) => ({
                    ...sta,
                    value: sta.displayName === "Name" ? "Soil Type Name" : sta.displayName,
                    label: sta.displayName === "Name" ? "Soil Type Name" : sta.displayName,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
        }

        if (nextProps.showCopyReportBookModal) {
            this.openModal(COPY_REPORT_BOOK_MODAL);
            newState.newReportBookName = this.state.tempReportBookName;
            this.checkNewReportNameForErrors(newState.newReportBookName);
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState as IAddEditPanelState, () => {
                if (newState.selectedReportOptionGuid != null || newState.reportOptions != null) {
                    const selectedReportOption = this.state.reportOptions.find(
                        (ro) => ro.reportBookOptionsGuid === this.state.selectedReportOptionGuid
                    );
                    const filteredReportOptions =
                        selectedReportOption != null
                            ? this.generateFilteredReportOptions(
                                  selectedReportOption?.reportTypeGuid
                              )
                            : this.state.reportOptions;
                    this.setState({
                        filteredReportOptions,
                        selectedReportOption,
                        selectedReportOptionGuid:
                            this.state.selectedReportOptionGuid == null
                                ? null
                                : this.state.selectedReportOptionGuid,
                    });
                }
            });
        }
    }

    componentDidMount(): void {
        // eslint-disable-next-line react/no-find-dom-node
        this.dragDropNode = ReactDOM.findDOMNode(this.dragDropDiv) as Element; // ""Do not use findDOMNode. It doesn’t work with function components and is deprecated in StrictMode""
        this.dragDropNode.addEventListener("drop", this.handleDrop, false);
        window.addEventListener("dragover", this.handleDragOver, false);
    }

    componentWillUnmount(): void {
        this.dragDropNode.removeEventListener("drop", this.handleDrop, false);
        window.removeEventListener("dragover ", this.handleDragOver, false);
    }

    private addReportAndConfiguration() {
        //sort order === index in array
        if (this.state.addOrEditMode === "ADD") {
            const data = {
                reportBookOptionsGuid: this.state.selectedReportOptionGuid,
                sortOrder: this.state.reports.length,
            };
            const newReport = this.generateReportTableData(
                data,
                this.state.selectedReportType.displayName,
                this.state.selectedReportOption?.name
            );
            const reports = [...this.state.reports];
            if (reports.length > 0) {
                reports[reports.length - 1][model.PROPS_ADD_EDIT_TABLE_DOWN_BUTTON] =
                    this.generateReorderButton("desc", reports.length - 1);
            }
            this.setState({ reports: [...reports, newReport] }, () => {
                this.props.liftErrorData({
                    reportBookNameError:
                        this.checkReportNameForErrors(this.state.tempReportBookName) ||
                        this.state.tempReportBookName == null ||
                        this.state.tempReportBookName.trim() === "",
                    reportsLength: this.state.reports.length,
                    orgLevelGuidsLength: this.state.selectedOrgLevels.length,
                });
            });
        } else if (this.state.addOrEditMode === "EDIT") {
            const reports = [...this.state.reports];
            reports[this.state.currentReportIndex][model.PROPS_REPORT] =
                this.state.selectedReportType.displayName;
            reports[this.state.currentReportIndex][model.PROPS_CONFIGURATION] =
                this.state.selectedReportOption?.name;
            reports[this.state.currentReportIndex][model.PROPS_ADD_EDIT_TABLE_DATA] = {
                ...reports[this.state.currentReportIndex][model.PROPS_ADD_EDIT_TABLE_DATA],
                reportBookOptionsGuid: this.state.selectedReportOptionGuid,
            };
            this.setState({ reports }, () => {
                this.props.liftErrorData({
                    reportBookNameError:
                        this.checkReportNameForErrors(this.state.tempReportBookName) ||
                        this.state.tempReportBookName == null ||
                        this.state.tempReportBookName.trim() === "",
                    reportsLength: this.state.reports.length,
                    orgLevelGuidsLength: this.state.selectedOrgLevels.length,
                });
            });
        }

        this.closeModal(REPORT_BOOK_MODAL);
    }

    private checkConfigNameForErrors = (value: string) => {
        const configNames =
            this.state.filteredReportOptions.length === 0
                ? []
                : this.state.filteredReportOptions
                      .filter(
                          (fro) =>
                              fro?.name.toLowerCase() !==
                              this.state.selectedReportOption?.name.toLowerCase()
                      )
                      .map((fro) => fro?.name.toLowerCase());
        this.setState({
            configurationNameError: configNames.includes(value?.toLowerCase()),
        });
    };

    private checkNewReportNameForErrors = (value: string) => {
        const reportBookNewNameError = this.state.reportBookNames
            .map((rbn) => rbn?.name.toLowerCase())
            .includes(value?.toLowerCase());
        this.setState({ reportBookNewNameError });
    };

    private checkReportNameForErrors = (value: string): boolean => {
        const reportBookNames =
            this.state.reportBookNames.length === 0
                ? []
                : this.state.reportBookNames
                      .filter(
                          (rbn) =>
                              rbn?.name.toLowerCase() !==
                              this.state.reportBookInfo[model.PROPS_NAME]?.toLowerCase()
                      )
                      .map((rbn) => rbn?.name.toLowerCase());
        const reportBookNameError = reportBookNames.includes(value?.toLowerCase());
        this.setState({ reportBookNameError });
        return reportBookNameError;
    };

    private closeModal(id: string) {
        const otherModals = this.state.modals.filter((modal) => {
            return modal.id !== id;
        });
        let modalToUpdate = this.state.modals.filter((modal) => {
            return modal.id === id;
        });
        modalToUpdate = [{ ...modalToUpdate[0], isOpen: false }];

        const updatedModals = [...otherModals].concat(modalToUpdate);

        const newState: Partial<IAddEditPanelState> = {};

        if (id === REPORT_BOOK_MODAL) {
            newState.addOrEditMode = ADD;
            newState.currentReportIndex = null;
            newState.filteredReportOptions = this.state.reportOptions;
            newState.selectedReportType = null;
            newState.selectedReportTypeGuid = null;
            newState.selectedReportOption = null;
            newState.selectedReportOptionGuid = null;
        }

        if (id === DELETE_CONFIRMATION_MODAL) {
            newState.selectedItemIndex = null;
        }

        newState.modals = updatedModals;

        this.setState(newState as IAddEditPanelState);
    }

    private confirmDelete = (options) => {
        const { selectedItems } = options;
        const selectedItemIndex = selectedItems[0].guid;
        this.setState({ selectedItemIndex });
        this.openModal(DELETE_CONFIRMATION_MODAL);
    };

    private copyReportBook = () => {
        const reportBookInfo = {
            ...this.state.reportBookInfo,
            [model.PROPS_NAME]: this.state.newReportBookName,
        };

        this.setState({ processing: true }, () => {
            const copyReportBookInfo = () => {
                this.setState(
                    {
                        reportBookInfo,
                        tempReportBookName: reportBookInfo[model.PROPS_NAME],
                    },
                    () => {
                        this.setState({ processing: false }, () => {
                            this.closeModal(COPY_REPORT_BOOK_MODAL);
                        });
                    }
                );
            };
            if (reportBookInfo[model.PROPS_COVER_PAGE_URL] == null) {
                copyReportBookInfo();
            } else {
                const s3FileNameParts =
                    this.state.reportBookInfo[model.PROPS_COVER_PAGE_URL].split("/");
                if (s3FileNameParts.length > 2) {
                    const fromKey = decodeURI(
                        [...s3FileNameParts].splice(s3FileNameParts.length - 2).join("/")
                    );
                    SetupReportBooksAPI.copyReportBookCoverPageFile(fromKey).then((newKey) => {
                        const urlBase = s3FileNameParts
                            .splice(0, s3FileNameParts.length - 1)
                            .join("/");
                        reportBookInfo[model.PROPS_COVER_PAGE_URL] = `${urlBase}/${newKey}`;
                        copyReportBookInfo();
                    });
                }
            }
        });
        if (this.props.liftCopyReportBook != null) {
            this.props.liftCopyReportBook();
        }
    };

    private deleteCoverPage = () => {
        const s3FileNameParts = this.state.reportBookInfo[model.PROPS_COVER_PAGE_URL].split("/");
        if (s3FileNameParts.length > 2) {
            const s3Object = decodeURI(
                s3FileNameParts.splice(s3FileNameParts.length - 2).join("/")
            );
            this.props.onUpdateS3CleanupList(
                s3Object,
                this.state.selectedReportBookInfo == null ||
                    this.state.selectedReportBookInfo[model.PROPS_COVER_PAGE_URL] ===
                        this.state.reportBookInfo[model.PROPS_COVER_PAGE_URL]
            );
        }
        this.updateReportBookInfo(model.PROPS_COVER_PAGE_URL, null);
    };

    private deleteSelected = () => {
        const reports = [...this.state.reports];
        reports.splice(this.state.selectedItemIndex, 1);
        this.updateReportInfo(reports);
        this.closeModal(DELETE_CONFIRMATION_MODAL);
    };

    private generateFilteredReportOptions(reportTypeGuid: string) {
        return this.state.reportOptions.filter((ro) => ro.reportTypeGuid === reportTypeGuid);
    }

    private generateReportBookData() {
        const reports = this.state.reports?.map((report) => ({
            reportBookOptionsGuid: report.data.reportBookOptionsGuid,
            sortOrder: report.data.sortOrder,
        }));
        const orgLevelGuids = this.state.selectedOrgLevels?.map(
            (orgLevel) => orgLevel.orgLevelGuid
        );
        const reportBookInfo = {
            ...this.state.reportBookInfo,
            [model.PROPS_NAME]: this.state.tempReportBookName,
            [model.PROPS_ACTIVE_YN]:
                this.state.selectedReportBookTypeGuid == null
                    ? true
                    : this.state.reportBookInfo[model.PROPS_ACTIVE_YN],
        };
        const data = {
            ...reportBookInfo,
            orgLevelGuids,
            reports,
        };
        this.props.liftRecordData(data);
    }

    private generateReportTableData(
        data: { reportBookOptionsGuid: string; sortOrder: number },
        reportTypeName: string,
        reportOptionsName: string
    ) {
        const upButton =
            data.sortOrder === 0 ? null : this.generateReorderButton("asc", data.sortOrder);
        return {
            [model.PROPS_CAN_DELETE]: true,
            [model.PROPS_ADD_EDIT_TABLE_UP_BUTTON]: upButton,
            [model.PROPS_ADD_EDIT_TABLE_DOWN_BUTTON]: null,
            [model.PROPS_REPORT]: reportTypeName,
            [model.PROPS_CONFIGURATION]: reportOptionsName,
            [model.PROPS_ADD_EDIT_TABLE_DATA]: data,
        };
    }

    private generateReorderButton(reOrderDirection: string, index: number) {
        return (
            <span
                className={"reorder-button"}
                onClick={(e) => this.reorderReports(e, reOrderDirection, index)}
            >
                {reOrderDirection === "asc" ? (
                    <UpArrowIcon className="arrow-icon" />
                ) : (
                    <DownArrowIcon className="arrow-icon" />
                )}
            </span>
        );
    }

    private generateSelectedOrgLevels(selectedOrgLevelGuids: string[], orgLevelList: any[]) {
        return selectedOrgLevelGuids.map((orgLevelGuid) => {
            const selectedOrgLevel = orgLevelList.find((org) => org.orgLevelGuid === orgLevelGuid);
            if (selectedOrgLevel != null) {
                selectedOrgLevel.selected = true;
                return selectedOrgLevel;
            }
            return null;
        });
    }

    private handleDrop = (event) => {
        if (event.target.closest(".drop-cover-page-file")) {
            event.preventDefault();
            if (event.dataTransfer) {
                this.importFiles(event.dataTransfer.files);
            }
        }
    };

    private handleDragOver = (event) => {
        if (event.target.closest(".drop-cover-page-file")) {
            event.preventDefault();
        }
    };

    private importFiles(files: FileList = new FileList()) {
        if (files.length < 1 || this.state.reportBookInfo[model.PROPS_COVER_PAGE_URL] != null) {
            return;
        }
        const file = files[0];
        if (file.name.split(".").pop().toLowerCase().localeCompare("pdf") !== 0) {
            const { formatMessage } = this.props.intl;
            this.props.onPushToasterMessage(
                formatMessage(messages.coverPageMustBePdf),
                MSGTYPE.ERROR
            );
            return;
        }
        this.setState({ loadingS3CoverPage: true }, () => {
            SetupReportBooksAPI.getReportBookCoverPageS3PresignedUrl(file.name)
                .then((url) => {
                    Request.uploadToS3(url, file).then((e) => {
                        this.setState(
                            {
                                loadingS3CoverPage: false,
                                reportBookInfo: {
                                    ...this.state.reportBookInfo,
                                    [model.PROPS_COVER_PAGE_URL]: e.url.split("?")[0],
                                    [model.PROPS_COVER_PAGE]: true,
                                },
                            },
                            () => {
                                const s3FileNameParts =
                                    this.state.reportBookInfo[model.PROPS_COVER_PAGE_URL].split(
                                        "/"
                                    );
                                this.updateReportBookCoverPage(
                                    this.state.reportBookInfo[model.PROPS_COVER_PAGE]
                                );
                                if (s3FileNameParts.length > 2) {
                                    const s3Object = decodeURI(
                                        s3FileNameParts.splice(s3FileNameParts.length - 2).join("/")
                                    );
                                    this.props.onUpdateCurrentCoverPage(s3Object);
                                }
                            }
                        );
                    });
                })
                .catch((e) => {
                    this.props.onPushToasterMessage(e.message, MSGTYPE.ERROR);
                    this.setState({ loadingS3CoverPage: false });
                });
        });
    }

    private modifyConfiguration() {
        const reportData = this.props.reportData[this.state.selectedReportType.name];
        if (reportData[reportModel.PROPS_REPORT_IMAGE_CONFIG]) {
            reportData[reportModel.PROPS_REPORT_IMAGE_CONFIG] = JSON.stringify(
                reportData[reportModel.PROPS_REPORT_IMAGE_CONFIG]
            );
        }
        if (reportData[reportModel.PROPS_REPORT_TYPE_PAGE_SELECTION]) {
            reportData[reportModel.PROPS_REPORT_TYPE_PAGE_SELECTION] = JSON.stringify(
                reportData[reportModel.PROPS_REPORT_TYPE_PAGE_SELECTION]
            );
        }
        const data: IMergeReportOptionsData = {
            reportBookOptionsGuid: this.state.selectedReportOption?.reportBookOptionsGuid || null,
            name: this.state.configurationName,
            options: JSON.stringify(reportData),
            reportTypeGuid: this.state.selectedReportTypeGuid,
            orgLevelGuids: this.state.selectedOrgLevels?.map((orgLevel) => orgLevel.orgLevelGuid),
        };
        this.closeModal(REPORT_OPTIONS_MODAL);
        this.props.mergeReportOptions(data);
    }

    private onReportConfigurationNameInputChange = (value: any) => {
        this.checkConfigNameForErrors(value);
        this.setState({ configurationName: value });
    };
    private openAddEditModal = (modelName: string, mode: string, _?, data?) => {
        if (this.state.selectedOrgLevels.length <= 0) {
            this.setState({ validationMessage: messages.needLocation }, () => {
                this.openModal(VALIDATION_MODAL);
            });
            return;
        }
        const selectedReportOption =
            data == null
                ? null
                : this.state.reportOptions.find(
                      (ro) => ro.reportBookOptionsGuid === data.reportBookOptionsGuid
                  );
        const selectedReportType =
            data == null
                ? null
                : this.state.reportTypes.find(
                      (rt) => rt.reportTypeGuid === selectedReportOption?.reportTypeGuid
                  );
        const filteredReportOptions =
            data == null
                ? this.state.reportOptions
                : this.generateFilteredReportOptions(selectedReportType?.reportTypeGuid);
        this.setState({
            addOrEditMode: mode,
            currentReportIndex: data == null ? null : data.sortOrder,
            filteredReportOptions,
            selectedReportType,
            selectedReportTypeGuid:
                selectedReportType == null ? null : selectedReportType.reportTypeGuid,
            selectedReportOption,
            selectedReportOptionGuid: data == null ? null : data.reportBookOptionsGuid,
        });
        this.toggleAddEdit(mode, true);
    };

    private openModal(id: string) {
        const otherModals = this.state.modals.filter((modal) => {
            return modal.id !== id;
        });
        let modalToUpdate = this.state.modals.filter((modal) => {
            return modal.id === id;
        });
        modalToUpdate = [{ ...modalToUpdate[0], isOpen: true }];

        const updatedModals = [...otherModals].concat(modalToUpdate);

        if (id === REPORT_BOOK_MODAL) {
            if (this.state.reportTypes?.length === 0) {
                this.props.getReportTypes();
            }
            this.props.getReportOptions(null);
        }
        this.setState({
            modals: updatedModals,
        });
    }

    private openReportOptions() {
        //open report options
        //create configuration
        const selectedReportOption =
            this.state.selectedReportOption != null ? this.state.selectedReportOption : null;
        const configurationName = selectedReportOption?.name || null;
        const reportData = selectedReportOption?.options || {};
        const reportType = this.state.selectedReportType.name;
        this.props.resetReportData();
        this.props.mergeReportData(reportType, reportData);
        this.setState({ configurationName }, () => {
            this.openModal(REPORT_OPTIONS_MODAL);
        });
    }

    private reorderReports(e, reOrderDirection: string, index: number) {
        e.preventDefault();
        e.stopPropagation();
        const { reports } = this.state;
        const from = index;
        const to = reOrderDirection === "asc" ? index - 1 : index + 1;
        reports.splice(to, 0, reports.splice(from, 1)[0]);
        this.updateReportInfo(reports);
    }

    private setSelectedReportOption(guid: string) {
        // if guid is undefined then we've cleared the select input
        const selectedReportOption =
            guid == null
                ? null
                : this.state.reportOptions.find((ro) => ro.reportBookOptionsGuid === guid);
        const selectedReportType =
            guid == null
                ? this.state.selectedReportType
                : this.state.reportTypes.find(
                      (rt) => rt.reportTypeGuid === selectedReportOption?.reportTypeGuid
                  );
        const filteredReportOptions =
            guid == null
                ? this.state.filteredReportOptions
                : this.generateFilteredReportOptions(selectedReportType?.reportTypeGuid);
        this.setState({
            filteredReportOptions,
            selectedReportOption: selectedReportOption,
            selectedReportOptionGuid: guid,
            selectedReportType: selectedReportType,
            selectedReportTypeGuid: selectedReportType?.reportTypeGuid,
        });
    }

    private setSelectedReportType(guid: string) {
        const selectedReportType = this.state.reportTypes.find((rt) => rt.reportTypeGuid === guid);
        const filteredReportOptions = this.generateFilteredReportOptions(guid);
        this.setState({
            filteredReportOptions,
            selectedReportType,
            selectedReportTypeGuid: guid,
            selectedReportOption: null,
            selectedReportOptionGuid: null,
        });
    }

    private toggleAddEdit = (mode: string, showAddEditModal: boolean) => {
        if (showAddEditModal) {
            this.openModal(REPORT_BOOK_MODAL);
        } else {
            this.closeModal(REPORT_BOOK_MODAL);
        }
    };

    private updateNewReportBookName(value: string) {
        this.checkNewReportNameForErrors(value);
        this.setState({ newReportBookName: value });
    }

    private updateReportBookInfo(propName: string, value: any) {
        const { reportBookInfo } = { ...this.state };
        reportBookInfo[propName] = value;
        this.setState({ reportBookInfo });
    }

    private updateReportBookCoverPage(coverPage: boolean) {
        const { reportBookInfo } = { ...this.state };
        reportBookInfo[model.PROPS_COVER_PAGE] = coverPage;
        this.setState({ reportBookInfo }, () => {
            this.props.liftErrorData({
                reportBookNameError:
                    this.checkReportNameForErrors(this.state.tempReportBookName) ||
                    this.state.tempReportBookName == null ||
                    this.state.tempReportBookName.trim() === "",
                reportsLength: this.state.reports.length,
                orgLevelGuidsLength: this.state.selectedOrgLevels.length,
            });
        });
    }

    private updateReportBookName(value: any) {
        this.checkReportNameForErrors(value);
        this.setState({ tempReportBookName: value }, () => {
            this.props.liftErrorData({
                reportBookNameError:
                    this.checkReportNameForErrors(this.state.tempReportBookName) ||
                    this.state.tempReportBookName == null ||
                    this.state.tempReportBookName.trim() === "",
                reportsLength: this.state.reports.length,
                orgLevelGuidsLength: this.state.selectedOrgLevels.length,
            });
        });
    }

    private updateReportInfo(reports: IDataTableReport[]) {
        if (reports.length > 0) {
            reports.forEach((report, index) => {
                report[model.PROPS_ADD_EDIT_TABLE_UP_BUTTON] =
                    index === 0 ? null : this.generateReorderButton("asc", index);
                report[model.PROPS_ADD_EDIT_TABLE_DOWN_BUTTON] =
                    index === reports.length - 1 ? null : this.generateReorderButton("desc", index);
                report[model.PROPS_ADD_EDIT_TABLE_DATA].sortOrder = index;
            });
        }
        this.setState({ reports }, () => {
            this.props.liftErrorData({
                reportBookNameError:
                    this.checkReportNameForErrors(this.state.tempReportBookName) ||
                    this.state.tempReportBookName == null ||
                    this.state.tempReportBookName.trim() === "",
                reportsLength: this.state.reports.length,
                orgLevelGuidsLength: this.state.selectedOrgLevels.length,
            });
        });
    }

    private updateSelectedOrgLevelGuids(value: any) {
        this.setState({ selectedOrgLevels: value }, () => {
            this.props.liftErrorData({
                reportBookNameError:
                    this.checkReportNameForErrors(this.state.tempReportBookName) ||
                    this.state.tempReportBookName == null ||
                    this.state.tempReportBookName.trim() === "",
                reportsLength: this.state.reports.length,
                orgLevelGuidsLength: this.state.selectedOrgLevels.length,
            });
        });
    }

    render(): JSX.Element {
        const {
            configurationName,
            configurationNameError,
            filteredReportOptions,
            loadingModal,
            loadingOrgLevelGuids,
            loadingReportBookReports,
            loadingReportBooks,
            modals,
            newReportBookName,
            orgLevelList,
            reportBookInfo,
            reportBookNameError,
            reportBookNewNameError,
            reports,
            reportTypePages,
            reportTypes,
            selectedReportBookTypeGuid,
            selectedReportOption,
            selectedReportOptionGuid,
            selectedOrgLevels,
            selectedReportType,
            selectedReportTypeGuid,
            soilTypeAttributes,
            tempReportBookName,
        } = this.state;
        let { reportPreferences } = this.state;
        const { formatMessage } = this.props.intl;

        reportPreferences = setReportPreferences(
            reportPreferences,
            selectedReportOption?.options,
            soilTypeAttributes
        );

        const coverPageDisplayName = decodeURI(
            reportBookInfo[model.PROPS_COVER_PAGE_URL]?.split("/").pop()
        );

        return (
            <div className="report-book-creation-container-spacer">
                {!loadingOrgLevelGuids &&
                !loadingReportBookReports &&
                !loadingReportBooks ? null : (
                    <Loader type={LoaderTypes.LINE_SCALE} />
                )}
                <div className="report-book-creation-container">
                    <div className="name-options-location-container">
                        <div className="report-book-name-and-active-container">
                            <div className="report-book-name">
                                <TextInput
                                    hasError={reportBookNameError}
                                    errorText={formatMessage(messages.reportBookNameDuplicated)}
                                    tabIndex={0}
                                    maxLength={50}
                                    placeholderText={formatMessage(messages.reportBookName)}
                                    value={tempReportBookName || ""}
                                    onChange={(value) => this.updateReportBookName(value)}
                                    containerClassNames={["report-book-name"]}
                                    autoFocus
                                    required
                                />
                            </div>
                            {selectedReportBookTypeGuid == null ? null : (
                                <Checkbox
                                    label={formatMessage(messages.active)}
                                    onChange={(e, value) =>
                                        this.updateReportBookInfo(model.PROPS_ACTIVE_YN, value)
                                    }
                                    value={reportBookInfo[model.PROPS_ACTIVE_YN]}
                                />
                            )}
                        </div>
                        <div className="report-book-options">
                            <Section
                                headerText={formatMessage(messages.options)}
                                required
                                className="options"
                            >
                                <SubSection>
                                    <div
                                        className="cover-page-section drop-cover-page-file"
                                        ref={(d) => (this.dragDropDiv = d)}
                                    >
                                        <Checkbox
                                            label={formatMessage(messages.coverPage)}
                                            onChange={(_, value) =>
                                                this.updateReportBookCoverPage(value)
                                            }
                                            value={reportBookInfo[model.PROPS_COVER_PAGE] || false}
                                        />
                                        {this.state.loadingS3CoverPage ? (
                                            <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />
                                        ) : reportBookInfo[model.PROPS_COVER_PAGE_URL] == null ? (
                                            <Button
                                                type="upload"
                                                onClick={() => this.fileInput.click()}
                                            ></Button>
                                        ) : (
                                            <div className="cover-page-info">
                                                <a
                                                    title={coverPageDisplayName}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                        reportBookInfo[model.PROPS_COVER_PAGE_URL]
                                                    }
                                                >
                                                    {coverPageDisplayName}
                                                </a>
                                                <button
                                                    className="default-button"
                                                    onClick={() => this.deleteCoverPage()}
                                                >
                                                    <TrashcanIcon />
                                                </button>
                                            </div>
                                        )}
                                        <input
                                            ref={(e) => (this.fileInput = e)}
                                            style={{ display: "none" }}
                                            type="file"
                                            accept=".pdf"
                                            onChange={() => this.importFiles(this.fileInput.files)}
                                        />
                                    </div>
                                    <TextArea
                                        containerClassNames={["report-book-memo"]}
                                        value={reportBookInfo[model.PROPS_MEMO] || ""}
                                        onChange={(value) =>
                                            this.updateReportBookInfo(model.PROPS_MEMO, value)
                                        }
                                        placeholderText={formatMessage(messages.introMemo)}
                                        maxLength={1024}
                                    />
                                </SubSection>
                            </Section>
                            <div className="location-container">
                                <div className="location-selection-container">
                                    <Section headerText={formatMessage(messages.location)} required>
                                        <SubSection>
                                            <OrgLevelList
                                                disabled={reports.length > 0}
                                                classNames={getAgBytesErrorClassNames(
                                                    [2033],
                                                    this.props.apiErrors
                                                )}
                                                itemList={orgLevelList}
                                                onSelectionChange={(value) =>
                                                    this.updateSelectedOrgLevelGuids(value)
                                                }
                                                record={selectedOrgLevels}
                                                statePropName={model.PROPS_STATE_ABBREVIATION}
                                            />
                                        </SubSection>
                                    </Section>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="report-types-and-configurations-container">
                        <Section headerText={formatMessage(messages.reports)}>
                            <SubSection>
                                <DataTable
                                    disableSelectAll={true}
                                    isEditable
                                    isCheckbox={false}
                                    showFilterInput={false}
                                    service={addEditService}
                                    {...this.props}
                                    messages={messages}
                                    records={reports}
                                    deleteSelected={this.confirmDelete}
                                    openAddEditPanel={this.openAddEditModal}
                                    totalCount={reports.length}
                                    disableSort={true}
                                />
                            </SubSection>
                        </Section>
                    </div>

                    <DialogBox
                        className="report-type-and-configuration-modal"
                        draggable
                        footerType={DialogBoxFooterType.MULTI_ACTION_CANCEL}
                        forceOverflow
                        isOpen={
                            modals.filter((modal) => {
                                return modal.id === REPORT_BOOK_MODAL;
                            })[0].isOpen
                        }
                        multiActionList={[
                            {
                                action:
                                    selectedReportOptionGuid == null
                                        ? formatMessage(messages.createConfiguration)
                                        : formatMessage(messages.modifyConfiguration),
                                actionDisabled:
                                    selectedReportTypeGuid == null || reportPreferences == null,
                                onAction: () => this.openReportOptions(),
                                isCancelStyle: false,
                            },
                            {
                                action: formatMessage(messages.add),
                                actionDisabled:
                                    selectedReportTypeGuid == null ||
                                    selectedReportOptionGuid == null,
                                onAction: () => this.addReportAndConfiguration(),
                                isCancelStyle: false,
                            },
                        ]}
                        onClose={() => {
                            this.closeModal(REPORT_BOOK_MODAL);
                        }}
                        title={"Add Report to Book"}
                    >
                        {!loadingModal ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
                        <SelectInput
                            clearable={false}
                            onChange={(value) => this.setSelectedReportType(value)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={reportTypes}
                            placeholderText={formatMessage(messages.report)}
                            required
                            value={selectedReportTypeGuid}
                        />
                        <SelectInput
                            clearable={true}
                            onChange={(value) => this.setSelectedReportOption(value)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={filteredReportOptions}
                            placeholderText={formatMessage(messages.configuration)}
                            required
                            value={selectedReportOptionGuid}
                        />
                    </DialogBox>

                    <DialogBox
                        actionDisabled={
                            configurationName == null ||
                            configurationName === "" ||
                            configurationNameError
                        }
                        className="report-book-report-options-modal"
                        draggable
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                        forceOverflow
                        isOpen={
                            modals.filter((modal) => {
                                return modal.id === REPORT_OPTIONS_MODAL;
                            })[0].isOpen
                        }
                        onAction={() => this.modifyConfiguration()}
                        onClose={() => {
                            this.closeModal(REPORT_OPTIONS_MODAL);
                        }}
                        title={"Report Options"}
                    >
                        <div className="reports-container">
                            <div className="reports-options-container">
                                <TextInput
                                    tabIndex={0}
                                    maxLength={50}
                                    placeholderText={formatMessage(messages.configurationName)}
                                    value={configurationName || ""}
                                    onChange={(value) =>
                                        this.onReportConfigurationNameInputChange(value)
                                    }
                                    autoFocus
                                    required
                                    errorText={formatMessage(messages.invalidConfigurationName)}
                                    hasError={configurationNameError}
                                />
                                <ReportOptionsContainer
                                    createReport={this.modifyConfiguration}
                                    formatMessage={formatMessage}
                                    hideCreateButton={true}
                                    hideDefaultOptions={true}
                                    hideOptionsHeader={true}
                                    onReportOptionsConfigChange={(value) => {
                                        this.props.resetReportData();
                                        this.props.mergeReportData(
                                            this.state.selectedReportType.displayName,
                                            value
                                        );
                                    }}
                                    reportPreferences={reportPreferences}
                                    reportTypePages={reportTypePages}
                                    selectedReportTypes={[selectedReportType?.name]}
                                    reportData={this.props.reportData}
                                    onInputChange={(
                                        key: string,
                                        value: any,
                                        reportType: string
                                    ) => {
                                        this.props.setReportDataValue(reportType, key, value);
                                    }}
                                    useRedux={true}
                                />
                            </div>
                        </div>
                    </DialogBox>

                    <DialogBox
                        actionDisabled={reportBookNewNameError}
                        className="copy-report-book-confirmation-modal"
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                        isOpen={
                            modals.filter((modal) => {
                                return modal.id === COPY_REPORT_BOOK_MODAL;
                            })[0].isOpen
                        }
                        onAction={() => this.copyReportBook()}
                        onClose={() => {
                            this.setState({ newReportBookName: null }, () => {
                                this.closeModal(COPY_REPORT_BOOK_MODAL);
                            });
                        }}
                        title={"New Report Book Name"}
                    >
                        <TextInput
                            hasError={reportBookNewNameError}
                            errorText={formatMessage(messages.reportBookNameDuplicated)}
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.newReportBookName)}
                            value={newReportBookName || ""}
                            onChange={(value) => this.updateNewReportBookName(value)}
                            containerClassNames={["new-report-book-name"]}
                            autoFocus
                            required
                        />
                    </DialogBox>

                    <DialogBox
                        action={"Delete"}
                        className="delete-confirmation-modal"
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                        isOpen={
                            modals.filter((modal) => {
                                return modal.id === DELETE_CONFIRMATION_MODAL;
                            })[0].isOpen
                        }
                        onAction={() => this.deleteSelected()}
                        onClose={() => {
                            this.closeModal(DELETE_CONFIRMATION_MODAL);
                        }}
                        title={"Remove Report"}
                    >
                        <p>
                            Are you sure you want to remove the report and its configuration from
                            the report book?
                        </p>
                    </DialogBox>

                    <DialogBox
                        action={"Delete"}
                        className="validation-confirmation-modal"
                        footerType={DialogBoxFooterType.NONE}
                        isOpen={
                            modals.filter((modal) => {
                                return modal.id === VALIDATION_MODAL;
                            })[0].isOpen
                        }
                        onClose={() => {
                            this.closeModal(VALIDATION_MODAL);
                        }}
                        title={"Remove Report"}
                    >
                        <p>
                            {this.state.validationMessage == null
                                ? null
                                : formatMessage(this.state.validationMessage)}
                        </p>
                    </DialogBox>
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
